import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class TextractUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }


  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Textract Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Financial services</h4>
                <p>Accurately extract critical business data such as mortgage rates, applicant names, and invoice totals across a variety of financial forms to process loan and mortgage applications in minutes.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Healthcare and life sciences</h4>
                <p>Better serve your patients and insurers by extracting important patient data from health intake forms, insurance claims, and pre-authorization forms. Keep data organized and in its original context, and eliminate manual review of output.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Public sector</h4>
                <p>Easily extract relevant data from government-related forms such as small business loans, federal tax forms, and business applications with a high degree of accuracy.</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default TextractUseCase