import React, { Component } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom'
import Polly from './components/Polly';
import Transcribe from './components/Transcribe';
import Main from './components/Main';
import Comprehend from './components/Comprehend';
import Rekognition from './components/Rekognition';
import Translate from './components/Translate';
//import NavBar from './utilities/navbar';
import Textract from './components/Textract';
import RekognitionUseCase from './components/usecases/RekognitionUseCase';
import TranscribeUseCase from './components/usecases/TranscribeUseCase';
import TextractUseCase from './components/usecases/TextractUseCase';
import PollyUseCase from './components/usecases/PollyUseCase';
import ComprehendUseCase from './components/usecases/ComprehendUseCase';
import TranslateUseCase from './components/usecases/TranslateUseCase';
import LexUseCase from './components/usecases/LexUseCase';
import RekognitionCustom from './components/Custom';

class App extends Component
{

  render()
  {
    return (<Switch>
      <Route exact path='/' component={Main} />
      <Route path='/polly' component={Polly} />
      <Route path='/transcribe' component={Transcribe} />
      <Route path='/comprehend' component={Comprehend} />
      <Route path='/rekognition' component={Rekognition} />
      <Route path='/translate' component={Translate} />
      <Route path='/textract' component={Textract} />
      <Route path='/custom' component={RekognitionCustom} />

      <Route path='/usecases/rekognition' component={RekognitionUseCase} />
      <Route path='/usecases/transcribe' component={TranscribeUseCase} />
      <Route path='/usecases/textract' component={TextractUseCase} />
      <Route path='/usecases/polly' component={PollyUseCase} />
      <Route path='/usecases/comprehend' component={ComprehendUseCase} />
      <Route path='/usecases/translate' component={TranslateUseCase} />
      <Route path='/usecases/lex' component={LexUseCase} />

    </Switch>
    )
  }
}

export default App;
