import React, { Component } from 'react';
import NavBar from '../utilities/navbar';
import Footer from '../utilities/footer';
import { Form } from 'semantic-ui-react';
import Webcam from 'react-webcam';
import { AnalyzeDocumentCommand } from "@aws-sdk/client-textract";
import { TextractClient } from "@aws-sdk/client-textract";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"; // ES6 import
import dotenv from 'dotenv';
dotenv.config();

var dataUriToBuffer = require('data-uri-to-buffer');
var AWS = require('aws-sdk');
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY });
const textractClient = new TextractClient({
  region: AWS.config.region,
  credentials: fromCognitoIdentityPool({ identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY, clientConfig: { region: 'us-east-1' } })
});

class Textract extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      image: '',
      resultMessage: '',
      resultLabels: [],
      resultText: [],
      imageSrc: '',
      videoInputDevices: '',
      deviceId: ''

    }
    this.handleCapture = this.handleCapture.bind(this);
    this.sendImageToTextract = this.sendImageToTextract.bind(this);
    this.displayBlockInfo = this.displayBlockInfo.bind(this);
    this.getCameras = this.getCameras.bind(this);
    this.setDeviceId = this.setDeviceId.bind(this);

  }
  setRef = webcam =>
  {
    this.webcam = webcam;
  };

  handleCapture = () =>
  {
    //const imageSrc = ""
    const imageSrc = this.webcam.getScreenshot()
    this.sendImageToTextract(imageSrc)
  }

  displayBlockInfo = async (response) =>
  {
    try
    {
      let extractResult = [];
      let currentComponent = this;

      response.Blocks.forEach(block =>
      {
        if ("Text" in block && block.Text !== undefined)
        {
          if ("Confidence" in block && block.Confidence !== undefined)
          {
            extractResult.push({ Text: block.Text, Confidence: block.Confidence })
          }
        }
      });
      currentComponent.setState({ resultMessage: "Success" })
      currentComponent.setState({ resultText: extractResult })

    } catch (err)
    {
      console.log("Error", err);
    }

  }
  setDeviceId = (id) =>
  {
    this.setState({ deviceId: id })

  }
  getCameras = async () =>
  {

    let allDevices = await navigator.mediaDevices.enumerateDevices()
    let videoInputDevices = allDevices.filter(({ kind }) => kind === "videoinput")
    this.setState({ videoInputDevices: videoInputDevices })
  }
  sendImageToTextract = async (imageSrc) =>
  {

    // convert image to buffer from base64
    let buffer = dataUriToBuffer(imageSrc)

    const bucket = 'ai-demo-ppt'
    const photo = 'paystub.jpg'

    // Set params
    const params = {
      Document: {
        Bytes: buffer,
        //S3Object: {
        //  Bucket: bucket,
        // Name: photo
        // },
      },
      FeatureTypes: ['TABLES', 'FORMS', 'SIGNATURES'],
    }
    try
    {

      const analyzeDoc = new AnalyzeDocumentCommand(params);
      const res = await textractClient.send(analyzeDoc);
      // console.log(res)
      this.displayBlockInfo(res)
      return res; //
    } catch (err)
    {
      console.log('Analysze Doc Err', err)
    }
  }

  render()
  {
    let cameras;
    if (this.state.videoInputDevices !== '')
    {
      cameras = this.state.videoInputDevices.map((dev, i) =>
      {
        return (<button onClick={() => this.setDeviceId(dev.deviceId)}>{dev.label}</button>)
      })
    }
    let result, extractions;
    if (this.state.resultMessage !== '')
    {
      result = <p>{this.state.resultMessage}</p>

      extractions = this.state.resultText.map((obj, i) =>
      {
        return (<tr key={i}>
          <td>
            {obj.Text}
          </td>
          <td>
            {obj.Confidence}
          </td>
        </tr>
        )

      })

    }
    const videoConstraints = {
      deviceId: this.state.deviceId
    };
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Amazon Textract</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row text-left">
              <p><a href="https://aws.amazon.com/rekognition/" target="_blank" rel="noopener noreferrer">Amazon Textract</a>  is a service that automatically detects and extracts text and data from scanned documents. It goes beyond simple optical character recognition (OCR) to also identify the contents of fields in forms and information stored in tables </p>             <br></br>
              <p>In this example, we're going to show how easy it is to send a paystub to <code>Amazon Textract</code> for information extraction.</p>
              <p>
                Methods:<br></br>
                <ul><li><code><a href="https://docs.aws.amazon.com/rekognition/latest/dg/API_DetectLabels.html" target="_blank" rel="noopener noreferrer">extract()</a></code>: Extract information from documents!</li></ul>
              </p>
            </div>
            <div className="row">
              <div className="col-md-8 text-left">
                <div>
                  <h4>Step 1: Choose Camera</h4>
                  <div>{cameras}</div>
                  <button type="button" className="btn btn-info" onClick={this.getCameras}>Get Cameras</button>
                </div>
              </div>
              <div className="col-md-16 text-left">
                <div>
                  <h4>Step 2: Upload Picture</h4>
                  <button type="button" className="btn btn-info" onClick={this.handleCapture}>Extract</button>
                  <br />
                </div>
                <Form>
                  <Webcam
                    audio={false}
                    height={700}
                    width={1000}
                    ref={this.setRef}
                    screenshotFormat="image/png"
                    //screenshotWidth={IMAGE_WIDTH} // no sense capturing images in a resolution higher than what resnet wants
                    videoConstraints={videoConstraints}
                  />
                </Form>
              </div>
              <div className="col-md-4 text-left">
                <h4>Results:</h4>{result}
                <table>
                  <thead>
                    <tr>
                      <th>
                        Text
                      </th>
                      <th>
                        Confidence
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {extractions}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div >
    )
  }
}

export default Textract