import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class TranslateUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }


  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Translate Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Translate user-generated content</h4>
                <p>Automatically translate high volumes of user-generated content, such as social media feed stories, profile descriptions, and comments, in real time.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Analyze online conversations in different languages</h4>
                <p>Understand sentiments toward your brand, product, or service using a natural language processing (NLP) application that analyzes text in multiple languages.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Create cross-lingual communications between user</h4>
                <p>Add real-time translation within chat, email, helpdesk, and ticketing applications so an English-speaking agent can communicate with customers across multiple languages.</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default TranslateUseCase