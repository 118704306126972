import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class PollyUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }
  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Polly Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Generate speech in dozens of languages</h4>
                <p>Add speech to applications with a global audience, such as RSS feeds, websites, or videos.</p>
                <a href="https://docs.aws.amazon.com/polly/latest/dg/available-voices.html">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
                <br />

              </div>
              <div className="col-md-8 text-left">
                <h4>Engage customers with a natural-sounding voice</h4>
                <p>Store and replay Amazon Polly speech output to prompt callers through interactive or automated voice response systems.</p>
                <a href="https://docs.aws.amazon.com/polly/latest/dg/neural-voices.html">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4>Adjust speaking style, speech rate, pitch, and loudness</h4>
                <p>Use SSML, a W3C standard XML-based markup language for speech synthesis applications, to support common SSML tags for phrasing, emphasis, and intonation.</p>
                <a href="https://docs.aws.amazon.com/polly/latest/dg/ssml.html">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <br />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default PollyUseCase