import React, { Component } from 'react'

export default class Footer extends Component
{
  render()
  {
    return (
      <div className="row">
        <div className="footer-demo text-center">
          <style>position:relative;</style>
          <p>Originally created by <a href="https://twitter.com/thenickwalsh" target="_blank" rel="noopener noreferrer">Nicholas Walsh</a> of AWS. Updated by Particular Presence Technologies
            <br></br>
            <a href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy</a> | <a href="https://aws.amazon.com/terms/" target="_blank" rel="noopener noreferrer">Site Terms</a> | © 2021, Amazon Web Services, Inc. or its affiliates. All rights reserved.</p>
        </div>
      </div>
    )
  }
}