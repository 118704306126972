import React, { Component } from 'react';
import NavBar from '../utilities/navbar';
import Footer from '../utilities/footer';
import { Form } from 'semantic-ui-react';
import Webcam from 'react-webcam';
import dotenv from 'dotenv';

dotenv.config();

var dataUriToBuffer = require('data-uri-to-buffer');
var AWS = require('aws-sdk');
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY });
let pollyCalled = false;


class Rekognition extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      image: '',
      resultMessage: '',
      resultLabels: [],
      imageSrc: '',
      pollyUrl: '',
      text: '',
      videoInputDevices: '',
      deviceId: ''
    }
    this.handleCapture = this.handleCapture.bind(this);
    this.sendImageToRekognition = this.sendImageToRekognition.bind(this);
    this.sendTextToPolly = this.sendTextToPolly.bind(this);
    this.getCameras = this.getCameras.bind(this);
    this.setDeviceId = this.setDeviceId.bind(this);
    this.getFirstThreeAndLastTwoLabels = this.getFirstThreeAndLastTwoLabels.bind(this);


  }
  setRef = webcam =>
  {
    this.webcam = webcam;
  };

  handleCapture = () =>
  {
    //const imageSrc = ""
    const imageSrc = this.webcam.getScreenshot()
    pollyCalled = false;
    this.sendImageToRekognition(imageSrc)
  }


  sendTextToPolly = async (name, confidence) =>
  {
    const text = `Amazon Rekognition thinks that you're a ${name} with ${confidence.toString().slice(0, 5)}% confidence`

    // Create synthesizeSpeech params JSON
    var speechParams = {
      OutputFormat: "mp3",
      //SampleRate: "16000",
      Text: text,
      TextType: "text",
      Engine: "standard", //standard, neural
      VoiceId: "Justin"
    };

    //your polly call goes here, this is extra credit!
    // Create the Polly service object and presigner object
    var polly = new AWS.Polly({ apiVersion: '2016-06-10' });
    var signer = new AWS.Polly.Presigner(speechParams, polly)
    let currComponent = this;
    let audioUrl = ''

    // Create presigned URL of synthesized speech file
    signer.getSynthesizeSpeechUrl(speechParams, (error, url) =>
    {
      if (error)
      {
        console.log("Get speech error", error)
        //currComponent.setState({ resultMessage: error.message });
      } else
      {
        audioUrl = url
      }

    });

    let audio = new Audio(audioUrl);
    audio.play()
    audioUrl = ''
  }
  setDeviceId = (id) =>
  {
    this.setState({ deviceId: id })
  }
  getCameras = async () =>
  {

    let allDevices = await navigator.mediaDevices.enumerateDevices()
    let videoInputDevices = allDevices.filter(({ kind }) => kind === "videoinput")
    this.setState({ videoInputDevices })
  }

  getFirstThreeAndLastTwoLabels = (allLabels) =>
  {
    const labels = []
    if (allLabels.length > 5)
    {
      //Push first 3 labels
      for (let i = 0; i < 3; i++)
      {
        labels.push(allLabels[i])
      }
      //Push last 2 labels
      for (let i = allLabels.length - 1; i > allLabels.length - 3; i--)
      {
        labels.push(allLabels[i])
      }
    } else
    {
      //Push all labels
      for (let i = 0; i < 5; i++)
      {
        labels.push(allLabels[i])
      }
    }
    return labels
  }
  sendImageToRekognition = async (imageSrc) =>
  {

    // convert image to buffer from base64
    let buffer = dataUriToBuffer(imageSrc)

    // API call params
    var RekognitionParams = {
      Image: {
        Bytes: buffer
        /* Alternatively, you can provide an S3 object */
        //S3Object: {
        //  Bucket: 'ai-demo-ppt',
        //  Name: 'Pug.png',
        //  }
      },
    };

    // instantiate Rekognition client
    var rekognition = new AWS.Rekognition({ apiVersion: '2017-07-01' });
    let currentComponent = this;

    // call Rekognition's detectLabels method
    rekognition.detectLabels(RekognitionParams, function (err, data)
    {
      if (err)
      {
        console.log('Detect labels err', err)
        currentComponent.setState({ resultMessage: err.message });
      }
      else
      {
        // console.log(data);
        const labels = currentComponent.getFirstThreeAndLastTwoLabels(data.Labels);

        currentComponent.setState({ resultLabels: labels });
        currentComponent.setState({ resultMessage: "Classification successful!" })
      }
    });

  }


  render()
  {
    let cameras;
    if (this.state.videoInputDevices !== '')
    {
      cameras = this.state.videoInputDevices.map((dev, i) =>
      {
        return (<button onClick={() => this.setDeviceId(dev.deviceId)}>{dev.label}</button>)
      })
    }

    let result, labels;
    if (this.state.resultMessage !== '')
    {
      result = <p>{this.state.resultMessage}</p>
      labels = this.state.resultLabels.map((label, i) =>
      {
        if (!pollyCalled && i === 0)
        {
          this.sendTextToPolly(label.Name, label.Confidence)
          pollyCalled = true
        }
        return (<tr key={i}>
          <td>
            {label.Name}
          </td>
          <td>
            {label.Confidence}
          </td>
        </tr>
        )
      })

    }
    const videoConstraints = {
      deviceId: this.state.deviceId
    };
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Amazon Rekognition</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row text-left">
              <p><a href="https://aws.amazon.com/rekognition/" target="_blank" rel="noopener noreferrer">Amazon Rekognition</a> makes it easy to add image and video analysis to your applications. You just provide an image or video to the Amazon Rekognition API, and the service can identify objects, people, text, scenes, and activities. It can detect any inappropriate content as well. Amazon Rekognition also provides highly accurate facial analysis and facial recognition. You can detect, analyze, and compare faces for a wide variety of use cases, including user verification, cataloging, people counting, and public safety.</p>
              <br></br>
              <p>In this example, we're going to show how easy it is to send an image to <code>Amazon Rekognition</code> to perform object identification.</p>
              <p>
                Methods:<br></br>
                <ul><li><code><a href="https://docs.aws.amazon.com/rekognition/latest/dg/API_DetectLabels.html" target="_blank" rel="noopener noreferrer">detectLabels()</a></code>: Detect object labels from an input image!</li></ul>
              </p>
            </div>
            <div className="row">
              <div className="col-md-8 text-left">
                <div>
                  <h4>Step 1: Choose Camera</h4>
                  <div>{cameras}</div>
                  <button type="button" className="btn btn-info" onClick={this.getCameras}>Get Cameras</button>
                  <br />
                </div>
              </div>
              <div className="col-md-16 text-left">
                <h4>Step 2: Take picture</h4>
                <Form>
                  <Webcam
                    audio={false}
                    height={700}
                    width={1000}
                    ref={this.setRef}
                    screenshotFormat="image/png"
                    //screenshotWidth={IMAGE_WIDTH} // no sense capturing images in a resolution higher than what resnet wants
                    videoConstraints={videoConstraints}
                  />
                </Form>
                <button type="button" className="btn btn-info" onClick={this.handleCapture}>Detect Labels</button>
              </div>
              <div className="col-md-4 text-left">
                <h4>Results:</h4>{result}
                <table>
                  <thead>
                    <tr>
                      <th>
                        Label
                      </th>
                      <th>
                        Confidence
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {labels}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Rekognition