import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class RekognitionUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }


  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Rekognition Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Detect inappropriate content</h4>
                <p>Quickly and accurately identify unsafe or inappropriate content across image and video assets based on general or business-specific standards and practices.</p>
                <a href="https://aws.amazon.com/rekognition/content-moderation/?pg=ln&sec=uc">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4>Verify identity online</h4>
                <p>Use facial comparison and analysis in your user onboarding and authentication workflows to remotely verify the identity of opted-in users.</p>
                <a href="https://aws.amazon.com/rekognition/identity-verification/?pg=ln&sec=uc">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4>Streamline media analysis</h4>
                <p>Automatically detect key video segments to reduce the time, effort, and costs of video ad insertion, content operations, and content production.</p>
                <a href="https://aws.amazon.com/rekognition/media-analysis/?pg=ln&sec=uc">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4>Send connected home smart alerts</h4>
                <p>Deliver timely and actionable alerts when a desired object is detected in your live video streams. Create home automation experiences such as automatically turning on the light when a person is detected.</p>
                <a href="https://aws.amazon.com/rekognition/connected-home/">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4>Workplace Safety</h4>
                <p>Automatically detect if persons in on-premises camera images are wearing the required PPE such as face covers, hand covers, and head covers.</p>
                <a href="https://aws.amazon.com/rekognition/workplace-safety/?nc=sn&loc=2&dn=3">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>


            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default RekognitionUseCase