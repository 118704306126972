import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class LexUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }


  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Transcribe Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Customer Service</h4>
                <p> Enable self-service capabilities with virtual contact center agents and interactive voice response (IVR) to solve customer queries faster.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Intelligent Routing</h4>
                <p>Build interfaces which seamlessly connect customers to the right human agent in the contact center.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Enterprise Productivity</h4>
                <p>Design conversational solutions that respond to frequently asked questions for technical support, HR benefits, finance and more.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Sales and Marketing</h4>
                <p>Automate user tasks in your applications like the CRM and across any digital channel of your choice. add CRM and upsell.</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default LexUseCase