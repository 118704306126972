import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class TranscribeUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }


  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Transcribe Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Get insights from customer conversations</h4>
                <p>With Transcribe Call Analytics, quickly extract actionable insights from customer conversations. AWS Contact Center Intelligence partners and Contact Lens for Amazon Connect offer turnkey solutions to improve customer engagement, increase agent productivity, and surface quality management alerts to supervisors.</p>
                <a href="https://aws.amazon.com/transcribe/call-analytics/">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
                <br />

              </div>
              <div className="col-md-8 text-left">
                <h4>Create subtitles and meeting notes</h4>
                <p>Use Amazon Transcribe Subtitling to boost productivity and accurately capture the meetings and conversations that matter to you. Subtitle your on-demand and broadcast content to increase accessibility and improve customer experience.</p>
                <a href="https://aws.amazon.com/transcribe/subtitling/?nc=sn&loc=2&dn=3">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4>Detect toxic content in audio</h4>
                <p>Use Transcribe Toxicity Detection for gaming, social media and other peer to peer conversations. Detect and categorize toxic audio and foster a safe and inclusive online environment.</p>
                <a href="https://aws.amazon.com/transcribe/toxicity-detection/">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
              <br />
              <div className="col-md-8 text-left">
                <h4>Improve clinical documentation</h4>
                <p>Medical doctors and practitioners can use Amazon Transcribe Medical to quickly and efficiently document clinical conversations into electronic health record (EHR) systems for analysis. The service is HIPAA- eligible and trained to understand medical terminology.</p>
                <a href="https://aws.amazon.com/transcribe/medical/?nc=sn&loc=7">
                  <button type="button" className="btn btn-info">Learn More</button>
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default TranscribeUseCase