import React, { Component } from 'react';
import NavBar from '../utilities/navbar';
import Footer from '../utilities/footer';
import { Form } from 'semantic-ui-react';
import Webcam from 'react-webcam';
import dotenv from 'dotenv';
import { RekognitionClient, DetectCustomLabelsCommand } from "@aws-sdk/client-rekognition"; // ES Modules import
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"; // ES6 import


dotenv.config();

var dataUriToBuffer = require('data-uri-to-buffer');
var AWS = require('aws-sdk');
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY });

class RekognitionCustom extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            image: '',
            resultMessage: '',
            resultLabels: [],
            imageSrc: '',
            text: '',
            videoInputDevices: '',
            deviceId: ''
        }
        this.handleCapture = this.handleCapture.bind(this);
        this.sendImageToRekognitionCustomLables = this.sendImageToRekognitionCustomLables.bind(this);
        this.getCameras = this.getCameras.bind(this);
        this.setDeviceId = this.setDeviceId.bind(this);

    }
    setRef = webcam =>
    {
        this.webcam = webcam;
    };

    handleCapture = () =>
    {
        //const imageSrc = ""
        const imageSrc = this.webcam.getScreenshot()
        this.sendImageToRekognitionCustomLables(imageSrc)
    }


    setDeviceId = (id) =>
    {
        this.setState({ deviceId: id })
    }
    getCameras = async () =>
    {

        let allDevices = await navigator.mediaDevices.enumerateDevices()
        let videoInputDevices = allDevices.filter(({ kind }) => kind === "videoinput")
        this.setState({ videoInputDevices })
    }

    sendImageToRekognitionCustomLables = async (imageSrc) =>
    {

        // convert image to buffer from base64
        let buffer = dataUriToBuffer(imageSrc)

        // API call params
        var RekognitionParams = {
            Image: {
                Bytes: buffer
                /* Alternatively, you can provide an S3 object */
                // S3Object: {

                //  Bucket: process.env.REACT_APP_CUSTOM_LABELS_S3_BUCKET,
                //   Name: process.env.REACT_APP_CUSTOM_LABELS_S3_OBJECT_NAME,
                // }
            },
            ProjectVersionArn: process.env.REACT_APP_CUSTOM_LABELS_MODEL_ARN,
        };

        // instantiate Rekognition client
        const client = new RekognitionClient({
            region: AWS.config.region,
            credentials: fromCognitoIdentityPool({ identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY, clientConfig: { region: AWS.config.region } })

        });
        const command = new DetectCustomLabelsCommand(RekognitionParams);
        let currentComponent = this;

        try
        {
            const response = await client.send(command);
            const labels = response["CustomLabels"]
            console.log('Detected Labels', labels)

            currentComponent.setState({ resultLabels: labels });
            if (labels.length === 0)
            {
                currentComponent.setState({ resultMessage: "Classification Failed!" })

            } else
            {
                currentComponent.setState({ resultMessage: "Classification successful!" })

            }

        } catch (err)
        {
            currentComponent.setState({ resultMessage: err.message });
            console.log('Detect custom labels Err', err)
        }

    }


    render()
    {
        let cameras;
        if (this.state.videoInputDevices !== '')
        {
            cameras = this.state.videoInputDevices.map((dev, i) =>
            {
                return (<button onClick={() => this.setDeviceId(dev.deviceId)}>{dev.label}</button>)
            })
        }

        let result, labels;
        if (this.state.resultMessage !== '')
        {
            result = <p>{this.state.resultMessage}</p>
            labels = this.state.resultLabels.map((label, i) =>
            {
                return (<tr key={i}>
                    <td>
                        {label.Name}
                    </td>
                    <td>
                        {label.Confidence}
                    </td>
                </tr>
                )
            })

        }
        const videoConstraints = {
            deviceId: this.state.deviceId
        };
        return (
            <div className="App">
                <NavBar />
                <div className="container">
                    <div className="content-wrap">
                        <div className="row text-left">
                            <h1>Amazon Rekognition Custom Labels</h1>
                        </div>
                        <div class="titlebar"></div>
                        <div className="row text-left">
                            <p><a href="https://docs.aws.amazon.com/rekognition/latest/customlabels-dg/what-is.html" target="_blank" rel="noopener noreferrer">Amazon Rekognition Custom Labels</a> makes it easy to identify the objects and scenes in images that are specific to your business needs. For example, you can find your logo in social media posts, identify your products on store shelves, classify machine parts in an assembly line, distinguish healthy and infected plants, or detect animated characters in videos.</p>
                            <br></br>
                            <p>In this example, we're going to show how easy it is to send an image to <code>Amazon Rekognition Custom Labels</code> to perform object identification.</p>
                            <p>
                                Methods:<br></br>
                                <ul><li><code><a href="https://docs.aws.amazon.com/rekognition/latest/APIReference/API_DetectCustomLabels.html" target="_blank" rel="noopener noreferrer">detectCustomLabels()</a></code>: Detect object labels from an input image!</li></ul>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-md-8 text-left">
                                <div>
                                    <h4>Step 1: Choose Camera</h4>
                                    <div>{cameras}</div>
                                    <button type="button" className="btn btn-info" onClick={this.getCameras}>Get Cameras</button>
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-16 text-left">
                                <h4>Step 2: Take picture</h4>
                                <Form>
                                    <Webcam
                                        audio={false}
                                        height={700}
                                        width={1000}
                                        ref={this.setRef}
                                        screenshotFormat="image/png"
                                        //screenshotWidth={IMAGE_WIDTH} // no sense capturing images in a resolution higher than what resnet wants
                                        videoConstraints={videoConstraints}
                                    />
                                </Form>
                                <button type="button" className="btn btn-info" onClick={this.handleCapture}>Detect Labels</button>
                            </div>
                            <div className="col-md-4 text-left">
                                <h4>Results:</h4>{result}
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Label
                                            </th>
                                            <th>
                                                Confidence
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {labels}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RekognitionCustom