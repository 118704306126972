import React, { Component } from 'react';
import NavBar from '../../utilities/navbar';
import Footer from '../../utilities/footer';

class ComprehendUseCase extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {

    }

  }


  render()
  {
    return (
      <div className="App">
        <NavBar />
        <div className="container">
          <div className="content-wrap">
            <div className="row text-left">
              <h1>Comprehend Use Cases</h1>
            </div>
            <div class="titlebar"></div>
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Mine business and call center analytics</h4>
                <p>Detect customer sentiment and analyze customer interactions and automatically categorize inbound support requests. Extract insights from customer surveys to improve your products. </p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Index and search product reviews</h4>
                <p>Focus on context by equipping your search engine to index key phrases, entities, and sentiment, not just keywords.</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Legal briefs management</h4>
                <p>Automate the extraction of insights from packets of legal briefs such as contracts and court records. Further secure your documents by identifying and redacting Personally Identifiable Information (PII).</p>
              </div>
              <div className="col-md-8 text-left">
                <h4>Process financial documents</h4>
                <p>Classify and extract entities from financial services documents such as insurance claims or mortgage packages or find relationships between financial events in a financial article. </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default ComprehendUseCase